.productImg {
  text-align: center;
}
.productImg img {
  max-width: 100%;
}
.attrEditBtn {
  float: right;
  margin-top: -6px;
}
