.productImg {
  text-align: center;
  max-width: 250px;
  float: left;
  border: 1px solid #ccc;
  padding: 5px;
}
.productImg img {
  max-width: 100%;
}
.bulkPricing {
  display: inline-flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: stretch;
  border-right: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
}
.bulkPricing :first-child {
  background: #eee;
}
.bulkPricing .bulkPricingUnit div {
  padding: 5px 20px;
  border-top: 1px solid #ccc;
  border-left: 1px solid #ccc;
  font-weight: bold;
  text-align: center;
}
.bulkPricing .bulkPricingUnit .qty {
  background: #eee;
}
.attrEditBtn {
  float: right;
  margin-top: -6px;
}
