.displayField {
  padding-left: 4px;
}
.displayField .label {
  font-weight: 600;
  color: grey;
  font-size: 13px;
  margin-bottom: 6px;
}
.displayField .value {
  height: 20px;
  font-size: 13px;
  margin-bottom: 12px;
  color: rgba(0, 0, 0, 0.7);
}
