.boldText {
  font-weight: bold;
}
.mainTable {
  width: 100%;
}
.mainTable tbody > tr > td {
  padding: 8px;
  font-size: 14px;
}
.detailsTable {
  margin-top: 32px;
}
.detailsTable tbody > tr > td,
.detailsTable thead > tr > th {
  padding: 8px;
}
