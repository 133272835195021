.commissionTable {
  margin-top: 32px;
  thead > tr > th,
  tbody > tr > td {
    padding: 8px;
  }
}
.footer {
  font-weight: bold;
}

@wplus-red-color: #f64747;@wplus-green-color: #03a678;@wplus-gray-color: #bdc3c7;