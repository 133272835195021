@import (css) url('https://fonts.googleapis.com/css?family=Open+Sans:400,700');
@import '~antd/lib/style/themes/default.less';

body {
  margin: 0;
  padding: 0;
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 12px;
  color: #666;
}

.ant-input-number .ant-input-number-handler-wrap {
  opacity: 1;
}

.ant-descriptions {
  margin-bottom: 24px;
}

.view-data-list {
  margin-bottom: 24px;

  .ant-list-header {
    background: #fafafa;
    font-weight: bold;
  }
}

.auiContentWrapper {
  max-width: 100%;
  margin: 0 0;
  padding: 16px;
  overflow-y: auto;
  //background-color: #f9fbfd;
  .auiContentContainer {
    //background-color: #f9fbfd;
    //box-shadow: 0 1px 5px 0 rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 3px 1px -2px rgba(0,0,0,.12);
  }
}

.clear-float {
  float: none;
  clear: both;
  display: block;
}

.page-title {
  text-align: left;
  font-size: 20px;
  font-weight: bold;
  color: #444;
  margin-bottom: 16px;
}

a:focus {
  text-decoration: none;
  text-decoration-skip-ink: auto;
}

.form-footer-buttons {
  text-align: right;

  button {
    margin-left: 10px;
  }
}

.actionBar {
  padding-bottom: 16px;
  text-align: right;

  button {
    margin-left: 8px;
  }
}

.ant-legacy-form-item-label {
  width: 100%;
  text-align: left !important;
}

@wplus-red-color: #f64747;@wplus-green-color: #03a678;@wplus-gray-color: #bdc3c7;