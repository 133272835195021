.menuBar {
  background: #d9d9d9;
  min-height: 48px;
  padding: 8px 16px;
  margin-bottom: 8px;

  .title {
    margin: 0;
  }
  .actions {
    .actionsItems {
      text-align: right;
      > * {
        margin: 0 5px;
      }
      a,
      button,
      svg {
        font-size: 18px;
        color: gray;
        background-color: #eeee;
      }
      > * {
        margin-left: 8px;
      }
    }
  }
}

@wplus-red-color: #f64747;@wplus-green-color: #03a678;@wplus-gray-color: #bdc3c7;