.table {
  width: 100%;
}
.table .row {
  height: 30px;
  border-bottom: 1px solid #eee;
}
.table .row .product {
  padding-left: 16px;
  text-align: left;
  font-weight: bold;
}
.table .row .number {
  text-align: right;
}
.table .row .total {
  padding-right: 16px;
  text-align: right;
  font-weight: bold;
}
.table .row .header {
  padding: 16px;
}
.table .row .header_number {
  padding: 16px;
  text-align: right;
}
.orderTitle {
  border-bottom: 5px solid #f4a137;
}
