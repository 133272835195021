.commissionTable {
  margin-top: 32px;
}
.commissionTable thead > tr > th,
.commissionTable tbody > tr > td {
  padding: 8px;
}
.footer {
  font-weight: bold;
}
