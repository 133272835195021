.loginFormContainer {
  width: 500px;
  margin: 200px auto;
  padding: 25px 50px;
  border: 1px solid #ccc;
  background: #efefef;
  border-radius: 20px;
}

.logo {
  text-align: center;
  img {
    max-width: 75%;
    max-height: 135px;
  }
}

.information {
  font-size: 16px;
  font-weight: bold;
  margin: 15px 0;
}

.errorMsg {
  margin-bottom: 10px;
}

@wplus-red-color: #f64747;@wplus-green-color: #03a678;@wplus-gray-color: #bdc3c7;