/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.App {
  height: 100vh;
}
.header {
  background: #fff;
}
.logo {
  text-align: center;
  padding: 10px 0;
  background: #d9d9d9;
}
.logo img {
  max-width: 75%;
  max-height: 100px;
}
.layout {
  background: #fff;
}
.auiContentWrapper {
  overflow-y: auto;
}
