.productImg {
  text-align: center;

  img {
    max-width: 100%;
  }
}

.attrEditBtn {
  float: right;
  margin-top: -6px;
}

@wplus-red-color: #f64747;@wplus-green-color: #03a678;@wplus-gray-color: #bdc3c7;