.container {
  padding: 20px;
}
.addContainer {
  .addressTitle {
    padding: 5px;
    font-weight: 600;
    color: grey;
    font-size: 13px;
    margin-bottom: 6px;
  }
  .addName {
    font-weight: bold;
  }
  .addLine,
  .addName {
    display: block;
    width: 100%;
    padding: 5px;
  }
}

@wplus-red-color: #f64747;@wplus-green-color: #03a678;@wplus-gray-color: #bdc3c7;