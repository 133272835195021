.container {
  padding: 20px;
}
.addContainer .addressTitle {
  padding: 5px;
  font-weight: 600;
  color: grey;
  font-size: 13px;
  margin-bottom: 6px;
}
.addContainer .addName {
  font-weight: bold;
}
.addContainer .addLine,
.addContainer .addName {
  display: block;
  width: 100%;
  padding: 5px;
}
