.treeView {
  background: #eee;
  min-height: 100vh;
  padding: 16px;
}
.tree :global(.ant-tree-switcher) {
  color: rgba(0, 0, 0, 0.8) !important;
  background: #eee !important;
}
.buttonContainer {
  text-align: right;
  padding-top: 16px;
}
.form :global(.page-title) {
  margin-top: 16px;
}
