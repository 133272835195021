@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,700');
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
body {
  margin: 0;
  padding: 0;
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 12px;
  color: #666;
}
.ant-input-number .ant-input-number-handler-wrap {
  opacity: 1;
}
.ant-descriptions {
  margin-bottom: 24px;
}
.view-data-list {
  margin-bottom: 24px;
}
.view-data-list .ant-list-header {
  background: #fafafa;
  font-weight: bold;
}
.auiContentWrapper {
  max-width: 100%;
  margin: 0 0;
  padding: 16px;
  overflow-y: auto;
}
.clear-float {
  float: none;
  clear: both;
  display: block;
}
.page-title {
  text-align: left;
  font-size: 20px;
  font-weight: bold;
  color: #444;
  margin-bottom: 16px;
}
a:focus {
  text-decoration: none;
  text-decoration-skip-ink: auto;
}
.form-footer-buttons {
  text-align: right;
}
.form-footer-buttons button {
  margin-left: 10px;
}
.actionBar {
  padding-bottom: 16px;
  text-align: right;
}
.actionBar button {
  margin-left: 8px;
}
.ant-legacy-form-item-label {
  width: 100%;
  text-align: left !important;
}
