.fieldLabel {
  font-weight: bold;
  font-size: 13px;
  padding-bottom: 4px;
}
.fieldValue {
  font-size: 16px;
  min-height: 16px;
}
.cell {
  padding: 8px;
  padding-bottom: 24px;
}
.title {
  font-weight: bold;
  border-bottom: 1px solid #bdc3c7;
  margin-bottom: 16px;
  padding-bottom: 4px;
  font-size: 16px;
  margin-top: 16px;
}
.table {
  margin-left: 8px;
}
