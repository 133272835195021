.attrTable {
  width: 100%;
  overflow-x: auto;
  border-collapse: collapse;
}

.attrTable,
td,
th {
  border: 1px solid #ccc;
  padding: 8px;
}

.priceQtyTable {
  width: 100%;
  border-collapse: collapse;
  td,
  th {
    border: none;
    padding: 0px !important;
  }
}

.tierPriceTable {
  width: 600px;
  border-collapse: collapse;
  td,
  th {
    padding: 8px;
    text-align: center;
  }
}

.textAttr {
  :global(.ant-input-group-addon) {
    padding: 0px;
    :global(.ant-btn) {
      height: 30px;
    }
  }
}

@wplus-red-color: #f64747;@wplus-green-color: #03a678;@wplus-gray-color: #bdc3c7;