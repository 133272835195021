.attrTable {
  width: 100%;
  overflow-x: auto;
  border-collapse: collapse;
}
.attrTable,
td,
th {
  border: 1px solid #ccc;
  padding: 8px;
}
.priceQtyTable {
  width: 100%;
  border-collapse: collapse;
}
.priceQtyTable td,
.priceQtyTable th {
  border: none;
  padding: 0px !important;
}
.tierPriceTable {
  width: 600px;
  border-collapse: collapse;
}
.tierPriceTable td,
.tierPriceTable th {
  padding: 8px;
  text-align: center;
}
.textAttr :global(.ant-input-group-addon) {
  padding: 0px;
}
.textAttr :global(.ant-input-group-addon) :global(.ant-btn) {
  height: 30px;
}
