.selectSalesRep {
  width: 100%;
}
.customersTable {
  margin-top: 16px;
}
.customersTable thead > tr > th,
.customersTable tbody > tr > td {
  padding: 8px 8px;
}
.addForm {
  margin-top: 32px;
}
