.selectSalesRep {
  width: 100%;
}

.customersTable {
  margin-top: 16px;
  thead > tr > th,
  tbody > tr > td {
    padding: 8px 8px;
  }
}

.addForm {
  margin-top: 32px;
}

@wplus-red-color: #f64747;@wplus-green-color: #03a678;@wplus-gray-color: #bdc3c7;