.displayField {
  padding-left: 4px;
  .label {
    font-weight: 600;
    color: grey;
    font-size: 13px;
    margin-bottom: 6px;
  }

  .value {
    height: 20px;
    font-size: 13px;
    margin-bottom: 12px;
    color: rgba(0, 0, 0, 0.7);
  }
}

@wplus-red-color: #f64747;@wplus-green-color: #03a678;@wplus-gray-color: #bdc3c7;