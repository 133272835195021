.view {
  padding-top: 16px;
}
.actions {
  position: relative;
  height: 50px;
}
.actions .actionsItems {
  position: relative;
  top: 50%;
  margin-top: -10px;
  float: right;
}
