.treeView {
  background: #eee;
  min-height: 100vh;
  padding: 16px;
  border: 1px solid white;
}

.tree {
  :global(.ant-tree-switcher) {
    color: rgba(0, 0, 0, 0.8) !important;
    background: #eee !important;
  }
}

.buttonContainer {
  text-align: right;
  padding-top: 16px;
}

.category {
  margin-left: -8px;
}

.form {
  :global(.page-title) {
    margin-top: 16px;
  }
}

@wplus-red-color: #f64747;@wplus-green-color: #03a678;@wplus-gray-color: #bdc3c7;