.boldText {
  font-weight: bold;
}
.mainTable {
  width: 100%;
  tbody > tr > td {
    padding: 8px;
    font-size: 14px;
  }
}
.detailsTable {
  margin-top: 32px;
  tbody > tr > td,
  thead > tr > th {
    padding: 8px;
  }
}

@wplus-red-color: #f64747;@wplus-green-color: #03a678;@wplus-gray-color: #bdc3c7;