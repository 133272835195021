@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,700);
._1XzTw3fxUKuJWIHF6_y_rF {
  margin-top: 20px;
  margin-bottom: 50px;
  width: 100%;
}

.eCyHZQy2MvdvRJ_aPDrPG {
  width: 100%;
}
._324ZQW-vrn0dEx7GWhz_xM img {
  max-width: 100%;
  border: 1px solid #ddd;
}
._2MrhP4KdRHCJIAzqcWmnuB img {
  width: 123px;
  margin: 5px;
  padding: 5px;
  border: 1px solid #ddd;
}
._2MrhP4KdRHCJIAzqcWmnuB ._3YWn2ZcUB-Ew_cG9tWJ9ZR {
  border: 1px solid #f64747;
}

._1xVIFltEABzI7hVM9BCxh4 {
  font-weight: bold;
  font-size: 13px;
  padding-bottom: 4px;
}
._2-bowsmqKhaCpJFGBecH {
  font-size: 16px;
  min-height: 16px;
}
._1EG7-FnXHOJefeHU-0ilo6 {
  padding: 8px;
  padding-bottom: 24px;
}
._2T_5rpxEj5-c1tvnaOCMh3 {
  font-weight: bold;
  border-bottom: 1px solid #bdc3c7;
  margin-bottom: 16px;
  padding-bottom: 4px;
  font-size: 16px;
  margin-top: 16px;
}
._2jTQ_wuURX2SyckK3Hnaau {
  margin-left: 8px;
}

._3nlqz2B4IpxFGw50wsIvUL {
  display: inline-block;
}
.A8aBK4sEnoEbhHPMVKROt {
  float: right !important;
}

._34l6uinHDeyVSgDLV1fWjB {
  padding: 20px;
}
.zWIbhn1saIT5ya5YeDcXy .gwSNoqvPFqOj2TavxgnRq {
  padding: 5px;
  font-weight: 600;
  color: grey;
  font-size: 13px;
  margin-bottom: 6px;
}
.zWIbhn1saIT5ya5YeDcXy ._14_sGh3Y30puNrFBierlGS {
  font-weight: bold;
}
.zWIbhn1saIT5ya5YeDcXy ._3Lu4a5L30EFZzOG5OUzVMx,
.zWIbhn1saIT5ya5YeDcXy ._14_sGh3Y30puNrFBierlGS {
  display: block;
  width: 100%;
  padding: 5px;
}

.mZRxB35zrN6QR6gBcbsNm {
  padding-left: 4px;
}
.mZRxB35zrN6QR6gBcbsNm ._2aemIyPMYITwj7BtcZue1Z {
  font-weight: 600;
  color: grey;
  font-size: 13px;
  margin-bottom: 6px;
}
.mZRxB35zrN6QR6gBcbsNm ._3d_3fjCltortQDLDFns4vb {
  height: 20px;
  font-size: 13px;
  margin-bottom: 12px;
  color: rgba(0, 0, 0, 0.7);
}

._39d5T2vM5R0XaxOUoR7h9F {
  background: #d9d9d9;
  min-height: 48px;
  padding: 8px 16px;
  margin-bottom: 8px;
}
._39d5T2vM5R0XaxOUoR7h9F ._3fY1zyrMnS-P-FR4wumVmB {
  margin: 0;
}
._39d5T2vM5R0XaxOUoR7h9F ._1cLv-p0eG4O-uPrN_HBl6b ._19KuQPISxwgrCmhsrD4ZXD {
  text-align: right;
}
._39d5T2vM5R0XaxOUoR7h9F ._1cLv-p0eG4O-uPrN_HBl6b ._19KuQPISxwgrCmhsrD4ZXD > * {
  margin: 0 5px;
}
._39d5T2vM5R0XaxOUoR7h9F ._1cLv-p0eG4O-uPrN_HBl6b ._19KuQPISxwgrCmhsrD4ZXD a,
._39d5T2vM5R0XaxOUoR7h9F ._1cLv-p0eG4O-uPrN_HBl6b ._19KuQPISxwgrCmhsrD4ZXD button,
._39d5T2vM5R0XaxOUoR7h9F ._1cLv-p0eG4O-uPrN_HBl6b ._19KuQPISxwgrCmhsrD4ZXD svg {
  font-size: 18px;
  color: gray;
  background-color: #eeee;
}
._39d5T2vM5R0XaxOUoR7h9F ._1cLv-p0eG4O-uPrN_HBl6b ._19KuQPISxwgrCmhsrD4ZXD > * {
  margin-left: 8px;
}

._2GXqDexs8hqou_mAyomv5f {
  text-align: center;
  max-width: 100%;
}
._2GXqDexs8hqou_mAyomv5f img {
  max-width: 100%;
}

._2YyoHMntxlB2w9rDcmwnkt {
  width: 100%;
}

._3ovv_s5nRE_LeDT_HR8oaS {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  margin: 0;
  padding-top: 250px;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.45);
}

._1PzSP74IhNlYGGohmU-7_c {
  width: 100%;
  border-collapse: collapse;
}
._1PzSP74IhNlYGGohmU-7_c td,
._1PzSP74IhNlYGGohmU-7_c th {
  border: none;
  padding: 0px !important;
}
._3pHOIb1GteipcKV6w0t8fR {
  width: 600px;
  border-collapse: collapse;
}
._3pHOIb1GteipcKV6w0t8fR td,
._3pHOIb1GteipcKV6w0t8fR th {
  padding: 8px;
  text-align: center;
}

._2a09wsMRgFaaFqUWCLo3a- {
  background: #86e2d5;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
._2zFnSLykTLZJ8b2CI17wL_ {
  height: 100vh;
}
.hp0dWNLinm1ZynSgb9tO1 {
  background: #fff;
}
._153dWt6e629HPy8dIItrw5 {
  text-align: center;
  padding: 10px 0;
  background: #d9d9d9;
}
._153dWt6e629HPy8dIItrw5 img {
  max-width: 75%;
  max-height: 100px;
}
._2C9T5S0R1r1FkkQgbA8NDp {
  background: #fff;
}
.CA8m_VgjJBkIiR6DYdzK {
  overflow-y: auto;
}

.jFj6NoDMiSLalkYSzj1pu {
  margin-top: 20px;
}

.mNB5RRY6HMWNjyzAVragt {
  width: 500px;
  margin: 200px auto;
  padding: 25px 50px;
  border: 1px solid #ccc;
  background: #efefef;
  border-radius: 20px;
}
._1cMCfKKNzKBeSC6hDLkVml {
  text-align: center;
}
._1cMCfKKNzKBeSC6hDLkVml img {
  max-width: 75%;
  max-height: 135px;
}
._2WmoQkuRnyfD1vDrIPYRCb {
  font-size: 16px;
  font-weight: bold;
  margin: 15px 0;
}
.JmB0wTm9mBaoFlcZkVQ_F {
  margin-bottom: 10px;
}

.XPclqFizZ0LE87l6BTRhI .page-title {
  margin-top: 16px;
}

._11g1qrR7-ZHG1TWp0VkOO3 {
  padding-top: 16px;
  padding-right: 16px;
}
._q3dDtS7AZ_o5BqK1KROI {
  margin-top: 32px;
  text-align: center;
}
._q3dDtS7AZ_o5BqK1KROI img {
  max-width: 100%;
}

._1InOUQ1ANJGei1tw1knlJh {
  background: #eee;
  min-height: 100vh;
  padding: 16px;
}
._38onsW-9avBg4xbhH2FlBx .ant-tree-switcher {
  color: rgba(0, 0, 0, 0.8) !important;
  background: #eee !important;
}
._5aQ6j59PdsDvffneL5kmP {
  text-align: right;
  padding-top: 16px;
}
._3q9Jb0rXB1tboqcPedvF-K .page-title {
  margin-top: 16px;
}

.pT9pBxtyPW-9g3mBRhDrF {
  padding-top: 16px;
}
._35brYUeafTxdnpo0VdAEcJ {
  margin-top: 32px;
  text-align: center;
}
._35brYUeafTxdnpo0VdAEcJ img {
  max-width: 100%;
}

.zCA_OdZGEfdsAUeCDosOC .page-title {
  margin-top: 16px;
}

._2sQOJJTYDgkfqTU4z1m7yl {
  background: #eee;
  min-height: 100vh;
  padding: 16px;
  border: 1px solid white;
}
._3BKBhhC0r0siL7n4kheZSV .ant-tree-switcher {
  color: rgba(0, 0, 0, 0.8) !important;
  background: #eee !important;
}
._1KLTUHCHQYTgPXGP7ykvP_ {
  text-align: right;
  padding-top: 16px;
}
._3e1ixdZFm923Fka6RH3qo3 {
  margin-left: -8px;
}
._2DnQ4c-oukXbp3OSwzwjOL .page-title {
  margin-top: 16px;
}

._3dGQalfwMO42JQrrS_acot {
  padding-top: 16px;
}
.U5uLnd365pPdKJ8DIrAgB {
  margin-top: 32px;
  text-align: center;
}
.U5uLnd365pPdKJ8DIrAgB img {
  max-width: 100%;
}

._2jskrOSPSzWjx4qHe-cIp1 .page-title {
  margin-top: 16px;
}

.R12qCaq0jSy-2cYkA-rLp {
  background: #eee;
  min-height: 100vh;
  padding: 16px;
  border: 1px solid white;
}
._26fAUBjNdz9a8lWa3-BBCI .ant-tree-switcher {
  color: rgba(0, 0, 0, 0.8) !important;
  background: #eee !important;
}
._237QR0DerpldW2B6yAC8Nx {
  text-align: right;
  padding-top: 16px;
}
._1mJuCusOSZmnhXNqsVA4DW {
  margin-left: -8px;
}
.IXppP4Ziaq98nlu98aTcR .page-title {
  margin-top: 16px;
}


._2syKDfd2vVKBCyM9F7D1Vr {
  position: relative;
  height: 50px;
}
._2syKDfd2vVKBCyM9F7D1Vr ._3_bey9wWgJavAZVTMzGB4F {
  position: relative;
  top: 50%;
  margin-top: -10px;
  float: right;
}






.PhSks9WsMBGSNwhlbmL9c {
  padding-top: 16px;
}
._3RIGv8mZiqhIqlsMEsz2u9 {
  position: relative;
  height: 50px;
}
._3RIGv8mZiqhIqlsMEsz2u9 ._5OEYtSlCF-gwxScNeLOwG {
  position: relative;
  top: 50%;
  margin-top: -10px;
  float: right;
}

._1fitC-9h0vBUrEqjWmivM6 h1 {
  font-size: 28px;
}

.fGIuvZ9l4ixwO_CoDVq0M {
  position: relative;
  height: 50px;
}
.fGIuvZ9l4ixwO_CoDVq0M .BNMZBTWktYuu0tGTa0KCv {
  position: relative;
  top: 50%;
  margin-top: -10px;
  float: right;
}

._2dpLiMwNJQ42R3LT2bRFsw {
  position: relative;
  height: 50px;
}
._2dpLiMwNJQ42R3LT2bRFsw ._3Tpy7wNtdSWsYM14D1ADE4 {
  position: relative;
  top: 50%;
  margin-top: -10px;
  float: right;
}

._2FymMzL3J7sw30OxPaXOcW {
  width: 100%;
}
._2FymMzL3J7sw30OxPaXOcW ._3kEkCpxQqezQYrisTPI1-- {
  height: 30px;
  border-bottom: 1px solid #eee;
}
._2FymMzL3J7sw30OxPaXOcW ._3kEkCpxQqezQYrisTPI1-- ._2K-5aclRlL11qeQ4fpKY_K {
  padding-left: 16px;
  text-align: left;
  font-weight: bold;
}
._2FymMzL3J7sw30OxPaXOcW ._3kEkCpxQqezQYrisTPI1-- .h__dq6-701UWA5dvzvlre {
  text-align: right;
}
._2FymMzL3J7sw30OxPaXOcW ._3kEkCpxQqezQYrisTPI1-- ._24FAuHVwwPMvzE1JUXFEuS {
  padding-right: 16px;
  text-align: right;
  font-weight: bold;
}
._2FymMzL3J7sw30OxPaXOcW ._3kEkCpxQqezQYrisTPI1-- ._3any1Us68bMUj_RQyHQAsy {
  padding: 16px;
}
._2FymMzL3J7sw30OxPaXOcW ._3kEkCpxQqezQYrisTPI1-- ._2OtCvGqXlYlK5rDPU66YEG {
  padding: 16px;
  text-align: right;
}
._2A2q-Rw8XumKhN1XYxu5bK {
  border-bottom: 5px solid #f4a137;
}

._3dpTEenlLnYatXpekFtKxk ._1x879xNDjsEOSYp-50yZE2 {
  float: right;
}

._3f0PG6dAnfKhMg66uqxC7w {
  width: 100%;
}
._3f0PG6dAnfKhMg66uqxC7w ._2iz2pkGPDvDm0-Q1eiwkuz {
  height: 30px;
  border-bottom: 1px solid #eee;
}
._3f0PG6dAnfKhMg66uqxC7w ._2iz2pkGPDvDm0-Q1eiwkuz .c-NqD1wCYLYsscknU0KGW {
  padding-left: 16px;
  text-align: left;
  font-weight: bold;
}
._3f0PG6dAnfKhMg66uqxC7w ._2iz2pkGPDvDm0-Q1eiwkuz ._3o2BAhdy0ifAnER8XQ1MMk {
  text-align: right;
}
._3f0PG6dAnfKhMg66uqxC7w ._2iz2pkGPDvDm0-Q1eiwkuz ._2cBg0uPLTxz58TJbfsUay8 {
  padding-right: 16px;
  text-align: right;
  font-weight: bold;
}
._3f0PG6dAnfKhMg66uqxC7w ._2iz2pkGPDvDm0-Q1eiwkuz .E3Nx3wL5EDlQ6HHjWCa7P {
  padding: 16px;
}
._3f0PG6dAnfKhMg66uqxC7w ._2iz2pkGPDvDm0-Q1eiwkuz ._1ChO9aX2sG1QVghELtQAwD {
  padding: 16px;
  text-align: right;
}
._2kCwsnk3gDf3OICqkKhOiW {
  border-bottom: 5px solid #f4a137;
}

._23QSzHEqTpjDgKNHRcYI2O {
  padding-top: 16px;
}
._1LsdlmInu2toQ9YelFxf75 {
  position: relative;
  height: 50px;
}
._1LsdlmInu2toQ9YelFxf75 ._14Zpt7-gyI9CUWMESPrEPh {
  position: relative;
  top: 50%;
  margin-top: -10px;
  float: right;
}

._34tCJTmKfoPAjXRUrAJqd- {
  font-weight: bold;
  margin-top: 16px;
}
.biICie58tG0wPS9FlPkFv {
  margin-bottom: 12px;
}

._2P8HgJQwbWYn75Q2BLhaCl {
  text-align: center;
}
._2P8HgJQwbWYn75Q2BLhaCl img {
  max-width: 100%;
}
._36-QNLxuyjeLhVwid6qLtg {
  float: right;
  margin-top: -6px;
}










._7jJ7XtQq6iOH-xIUP0Nqf {
  text-align: center;
  max-width: 250px;
  float: left;
  border: 1px solid #ccc;
  padding: 5px;
}
._7jJ7XtQq6iOH-xIUP0Nqf img {
  max-width: 100%;
}
._3xssQNMMaFneP1Iay4H-LZ {
  display: inline-flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: stretch;
  border-right: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
}
._3xssQNMMaFneP1Iay4H-LZ :first-child {
  background: #eee;
}
._3xssQNMMaFneP1Iay4H-LZ ._2aEwAo6VaxejUXg2TbiFn_ div {
  padding: 5px 20px;
  border-top: 1px solid #ccc;
  border-left: 1px solid #ccc;
  font-weight: bold;
  text-align: center;
}
._3xssQNMMaFneP1Iay4H-LZ ._2aEwAo6VaxejUXg2TbiFn_ ._2wmCida2n4gpkMIszUxXAK {
  background: #eee;
}
._3QgaXAdE_WSr-yvWI6ha3D {
  float: right;
  margin-top: -6px;
}

._3OFGCq2OyfMmy9TydK9nXP {
  padding: 50px;
}

.ant-form-explain {
  margin-top: 5px;
}

._2cmTEj_oXLvYx7xC-FejcG {
  width: 100%;
  overflow-x: auto;
  border-collapse: collapse;
}
._2cmTEj_oXLvYx7xC-FejcG,
td,
th {
  border: 1px solid #ccc;
  padding: 8px;
}
._2MFr7i0D2tdTxasa-_3Y1E {
  width: 100%;
  border-collapse: collapse;
}
._2MFr7i0D2tdTxasa-_3Y1E td,
._2MFr7i0D2tdTxasa-_3Y1E th {
  border: none;
  padding: 0px !important;
}
._2s4s7c9AFiHoNaWxSm2KDq {
  width: 600px;
  border-collapse: collapse;
}
._2s4s7c9AFiHoNaWxSm2KDq td,
._2s4s7c9AFiHoNaWxSm2KDq th {
  padding: 8px;
  text-align: center;
}
._1iqetpTuyw9NOFdooXRjId .ant-input-group-addon {
  padding: 0px;
}
._1iqetpTuyw9NOFdooXRjId .ant-input-group-addon .ant-btn {
  height: 30px;
}


.hQOxuq392CPA4EsvRrweW button {
  margin-right: 32px;
}



._2sLtpP2ewsrhpKOmgrdhc3 h1 {
  font-size: 28px;
}

._2z0dekwk-edh4ByqLPu80X {
  position: relative;
  height: 50px;
}
._2z0dekwk-edh4ByqLPu80X .BFWCQSJHz_xVSIO-89Rop {
  position: relative;
  top: 50%;
  margin-top: -10px;
  float: right;
}

._20JglHcWs8kr-nxuRTBpBE {
  padding-top: 16px;
}
._20_dUirzdWEy8Ih8WNP5QB {
  position: relative;
  height: 50px;
}
._20_dUirzdWEy8Ih8WNP5QB ._1swoIlt63k7oA-q3iNB8Bl {
  position: relative;
  top: 50%;
  margin-top: -10px;
  float: right;
}

._2DiIvlKWibdzab2OveSlqC {
  position: relative;
  height: 50px;
}
._2DiIvlKWibdzab2OveSlqC ._2x6a8bzCjEYfmy88pAYmvO {
  position: relative;
  top: 50%;
  margin-top: -10px;
  float: right;
}


._3lElZFe2KBxPs45gaFy6jk {
  padding-top: 16px;
}
._-bgyqRVxef1jlCPBMcZl {
  position: relative;
  height: 50px;
}
._-bgyqRVxef1jlCPBMcZl .R5xhooNOAwKClokSwZIKp {
  position: relative;
  top: 50%;
  margin-top: -10px;
  float: right;
}

._3wEABPlKOJXbAWNQop-_5h h1 {
  font-size: 28px;
}

._1xPBOhiqM8nqD3dxn0s5Jf {
  position: relative;
  height: 50px;
}
._1xPBOhiqM8nqD3dxn0s5Jf .WFFUnd4bZEX6yKhyaXyG6 {
  position: relative;
  top: 50%;
  margin-top: -10px;
  float: right;
}

._1t0s6DRFMPrkO3JbBIp7aZ {
  position: relative;
  height: 50px;
}
._1t0s6DRFMPrkO3JbBIp7aZ .UgqlwFE8lALDiV-dpbqi1 {
  position: relative;
  top: 50%;
  margin-top: -10px;
  float: right;
}

._1z7e6ggJ8m_UvvcDgTUcbx {
  margin-top: 32px;
}
._3sSk-EFKJYKbmMqSCwoSgX {
  text-align: right;
  margin-top: 32px;
}



._3YdIMBIuYD-3B2P-mb-B6h {
  width: 100%;
}
._22Mm_6M7t344663hkKAFD8 {
  margin-top: 16px;
}
._22Mm_6M7t344663hkKAFD8 thead > tr > th,
._22Mm_6M7t344663hkKAFD8 tbody > tr > td {
  padding: 8px 8px;
}
._2xTSklbGfez10R4Vn50ngh {
  margin-top: 32px;
}

._3XOIv7vrQ0HKFDlLYCJVzF {
  margin-top: 32px;
}

._13npYSSW8VtXvOhzrGSxCZ {
  margin-top: 32px;
}
._13npYSSW8VtXvOhzrGSxCZ thead > tr > th,
._13npYSSW8VtXvOhzrGSxCZ tbody > tr > td {
  padding: 8px;
}
._2eemmxjFhmgvta3yYW91P1 {
  font-weight: bold;
}

.M_gv-QfhsySA-eSmOJFO {
  font-weight: bold;
}
._36NeTygR3egCcN8t5De2_e {
  width: 100%;
}
._36NeTygR3egCcN8t5De2_e tbody > tr > td {
  padding: 8px;
  font-size: 14px;
}
._1L5CREhfdntUz1BPCFbIw4 {
  margin-top: 32px;
}
._1L5CREhfdntUz1BPCFbIw4 tbody > tr > td,
._1L5CREhfdntUz1BPCFbIw4 thead > tr > th {
  padding: 8px;
}







/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
body {
  margin: 0;
  padding: 0;
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 12px;
  color: #666;
}
.ant-input-number .ant-input-number-handler-wrap {
  opacity: 1;
}
.ant-descriptions {
  margin-bottom: 24px;
}
.view-data-list {
  margin-bottom: 24px;
}
.view-data-list .ant-list-header {
  background: #fafafa;
  font-weight: bold;
}
.auiContentWrapper {
  max-width: 100%;
  margin: 0 0;
  padding: 16px;
  overflow-y: auto;
}
.clear-float {
  float: none;
  clear: both;
  display: block;
}
.page-title {
  text-align: left;
  font-size: 20px;
  font-weight: bold;
  color: #444;
  margin-bottom: 16px;
}
a:focus {
  text-decoration: none;
  -webkit-text-decoration-skip: ink;
          text-decoration-skip-ink: auto;
}
.form-footer-buttons {
  text-align: right;
}
.form-footer-buttons button {
  margin-left: 10px;
}
.actionBar {
  padding-bottom: 16px;
  text-align: right;
}
.actionBar button {
  margin-left: 8px;
}
.ant-legacy-form-item-label {
  width: 100%;
  text-align: left !important;
}

