.priceQtyTable {
  width: 100%;
  border-collapse: collapse;
}
.priceQtyTable td,
.priceQtyTable th {
  border: none;
  padding: 0px !important;
}
.tierPriceTable {
  width: 600px;
  border-collapse: collapse;
}
.tierPriceTable td,
.tierPriceTable th {
  padding: 8px;
  text-align: center;
}
