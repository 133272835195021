.screenLoader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  margin: 0;
  padding-top: 250px;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.45);
}
