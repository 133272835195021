.imageGallery {
  width: 100%;
}
.activeImage img {
  max-width: 100%;
  border: 1px solid #ddd;
}
.thumbnails img {
  width: 123px;
  margin: 5px;
  padding: 5px;
  border: 1px solid #ddd;
}
.thumbnails .active {
  border: 1px solid #f64747;
}
