.priceQtyTable {
  width: 100%;
  border-collapse: collapse;
  td,
  th {
    border: none;
    padding: 0px !important;
  }
}

.tierPriceTable {
  width: 600px;
  border-collapse: collapse;
  td,
  th {
    padding: 8px;
    text-align: center;
  }
}

@wplus-red-color: #f64747;@wplus-green-color: #03a678;@wplus-gray-color: #bdc3c7;