@import '~antd/lib/style/themes/default.less';

.App {
  height: 100vh;
}

.header {
  background: #fff;
}

.logo {
  text-align: center;
  padding: 10px 0;
  background: #d9d9d9;
  img {
    max-width: 75%;
    max-height: 100px;
  }
}

.layout {
  background: #fff;
}

.auiContentWrapper {
  overflow-y: auto;
}

@wplus-red-color: #f64747;@wplus-green-color: #03a678;@wplus-gray-color: #bdc3c7;