.menuBar {
  background: #d9d9d9;
  min-height: 48px;
  padding: 8px 16px;
  margin-bottom: 8px;
}
.menuBar .title {
  margin: 0;
}
.menuBar .actions .actionsItems {
  text-align: right;
}
.menuBar .actions .actionsItems > * {
  margin: 0 5px;
}
.menuBar .actions .actionsItems a,
.menuBar .actions .actionsItems button,
.menuBar .actions .actionsItems svg {
  font-size: 18px;
  color: gray;
  background-color: #eeee;
}
.menuBar .actions .actionsItems > * {
  margin-left: 8px;
}
