.view {
  padding-top: 16px;
}
.categoryImg {
  margin-top: 32px;
  text-align: center;
}
.categoryImg img {
  max-width: 100%;
}
