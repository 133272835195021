.view {
  padding-top: 16px;
  padding-right: 16px;
}

.categoryImg {
  margin-top: 32px;
  text-align: center;

  img {
    max-width: 100%;
  }
}

@wplus-red-color: #f64747;@wplus-green-color: #03a678;@wplus-gray-color: #bdc3c7;