.actions{
	position: relative;
	height: 50px;  
	.actionsItems{
		position: relative;
		top: 50%;
		margin-top: -10px;
		float: right;
	}
}
@wplus-red-color: #f64747;@wplus-green-color: #03a678;@wplus-gray-color: #bdc3c7;